import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError, timeoutWith, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantsettingsService {
  token_response:any;
  token: any; 
  defaultTimeout = 1000000;
  constructor(private http: HttpClient) { 
    this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
  }

  SaveFilesToS3Bucket(file: any,tenantid :any,folderpath:any) {
    debugger;
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('tenantId', tenantid);
    formdata.append('folderpath',folderpath);
    return this.http.post(environment.UploadFile, formdata, {
      responseType: 'json',
      reportProgress: true,
    }).pipe(timeoutWith(this.defaultTimeout, timer(this.defaultTimeout)),map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetTenantSettingsByTenantId() {
    return this.http.get(environment.GetTenantSettingsByTenantId + '/' + this.token_response.userId  + '/' + this.token_response.tenantId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  UpdateTenantSettingsByTenantId(tenantData:any){
    return this.http.post(environment.UpdateTenantSettingsByTenantId, tenantData, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}
