<!-- <header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
   
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="19">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>

     
      <form class="app-search d-none d-xl-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form>

      <div class="dropdown dropdown-mega d-none d-lg-block me-2" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle>
          {{ 'HEADER.MEGA_MENU' | translate}}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-8">

              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.UI.TITLE' | translate}}hhhhhhhhhhhhhhh</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.APPLICATIONS.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.ECOMMERCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CALENDAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.EMAIL' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.PROJECTS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.TASKS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CONTACTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.EXTRA_PAGES.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.LIGHT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMPACT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.MAINTENANCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMING SOON' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.TIMELINE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.FAQ' | translate}}</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img src="assets/images/megamenu-img.png" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none me-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" ngbDropdownToggle>
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu>

          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
       
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="ms-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
       
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block me-1" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
          <i class="bx bx-customize"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" ngbDropdownMenu>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="Github">
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble">
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox">
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack">
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block me-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;" >
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i
                        class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> {{ 'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">Henry</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          
          <a class="dropdown-item" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge bg-success float-end">11</span><i
              class="bx bx-wrench font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.SETTINGS' | translate}} </a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="onSettingsButtonClicked()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i class="bx {{item.icon}} me-1"></i>
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                  [routerLink]="subitem.link"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                   {{subitem.label | translate }}
              </a>
              <div *ngIf="item.subItems.length > 11">
                  <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a></div>
                  </div>
              </div>
              
                <div class="dropdown" *ngIf="hasItems(subitem)">
                  <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                    (click)="onMenuClick($event)">{{ subitem.label | translate }}
                    <div class="arrow-down"></div>
                  </a>
                  <div class="dropdown-menu">
                    <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                      <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                        href="javascript: void(0);" [routerLink]="subSubitem.link"
                        routerLinkActive="active">{{ subSubitem.label | translate }}</a>
                      <div class="dropdown" *ngIf="hasItems(subSubitem)">

                        <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                          (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                          <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu">
                          <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                            <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                              [routerLink]="subSubSubitem.link"
                              routerLinkActive="active">{{ subSubSubitem.label | translate }}</a>
                          </ng-template>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
             </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div> -->

<div class="headersticky fixed-top main-content">
  <div class="card-body">
    <div class="d-flex justify-content-between nav-tabs nav-tabs-custom nav-justified">
      <!-- <ul ngbNav #customNav="ngbNav" [activeId]="1">
        <li class="py-2">
          <img [src]="profilePicture" style="height: 30px; width: 30px;"
            class="rounded-circle border-2 border ms-2 me-2 border-logo bg-white">
        </li>
       
        <li [ngbNavItem]="0" class="flex-grow-0 nav-item py-2">
          <a ngbNavLink class="py-0  tab nav-link " routerLink="/SiteAudit/Dashboard"
            (click)="menuItemClick('/SiteAudit/Dashboard')" [class.active]="activatedtabfile== 'Dashboard'">
            <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1"><i class='bx bx-bar-chart-square me-1 icons-font'></i></span>
            <span class=" d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1"><span class="d-flex align-items-center"><i class='bx bx-bar-chart-square me-1 font-size-14'></i>Dashboard</span></span>
           
          </a>
        </li>
        <li [ngbNavItem]="8" class="flex-grow-0 py-2">
          <a ngbNavLink class="py-0 tab " routerLink="/SiteAudit/Audits/Audits-Completed"
          (click)="menuItemClick('/SiteAudit/Audits/Audits-Completed')" [class.active]="activatedtabfile== 'Audits'" (click)="getallauditscount()">
            <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1"><i class='bx bxs-check-shield me-1 icons-font'></i></span>
            <span class="d-sm-block1 newfontscolor newfontscolornew texttabswhite  py-1"><span class="d-flex align-items-center"><i class='bx bxs-check-shield me-1 font-size-14'></i>Audits</span></span>
          </a>
        </li>
        <li [ngbNavItem]="5" class="flex-grow-0 py-2">
          <a ngbNavLink class="py-0 files tab text-dark text-active-primary" routerLink="/SiteAudit/Issues"
          (click)="menuItemClick('/SiteAudit/Issues')" [class.active]="activatedtabfile== 'Issues'">
            <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1"><i class='bx bx-bug me-1 icons-font'></i></span>
            <span class="d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1"><span class="d-flex align-items-center"><i class='bx bx-bug me-1 font-size-14'></i>Issues</span></span>
          </a>
        </li>
        <li [ngbNavItem]="4" class="flex-grow-0 py-2">
          <a ngbNavLink class="py-0 text-dark tab text-active-primary" routerLink="/SiteAudit/Actions"
          (click)="menuItemClick('/SiteAudit/Actions')" [class.active]="activatedtabfile== 'Actions'">
           
              <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1"><i class='bx bx-task me-1 icons-font'></i></span>
            <span class="d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1"><span class="d-flex align-items-center"><i class='bx bx-task me-1 font-size-14'></i>Actions</span></span>
          </a>
        </li>
       
        <li [ngbNavItem]="6" class="flex-grow-0 py-2">
          <a ngbNavLink class="py-0 tab text-dark text-active-primary " routerLink="/SiteAudit/Calendar"
          (click)="menuItemClick('/SiteAudit/Calendar')" [class.active]="activatedtabfile== 'Calendar'">
            <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1"><i class='bx bx-calendar-event me-1 icons-font'></i></span>
            <span class="d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1"><span class="d-flex align-items-center"><i class='bx bx-calendar-event me-1 font-size-14'></i>Calendar</span></span>
          </a>
        </li>
        <li [ngbNavItem]="6" class="flex-grow-0 py-2">
          <a ngbNavLink class="py-0 tab text-dark text-active-primary " routerLink="/SiteAudit/Templates"
          (click)="menuItemClick('/SiteAudit/Templates')" [class.active]="activatedtabfile== 'Templates'">
            <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1"><i class='bx bxs-layout me-1 icons-font'></i></span>
            <span class="d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1"><span class="d-flex align-items-center"><i class='bx bxs-layout me-1 font-size-14'></i>Templates</span></span>
          </a>
        </li>
    
        <li [ngbNavItem]="8" class="flex-fill py-2">
          <a ngbNavLink class="py-0 tab text-dark text-active-primary" routerLink="/SiteAudit/PMSData"
          (click)="menuItemClick('/SiteAudit/PMSData')" [class.active]="activatedtabfile== 'PMSData'">
            <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1"><i class='bx bx-grid me-1 icons-font'></i></span>
            <span class="d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1"><span class="d-flex align-items-center newfontscolornew"><i class='bx bx-grid me-1 font-size-14'></i>PMS Data</span></span>
          </a>
        </li>

        <li [ngbNavItem]="9" class="dropdown py-2" ngbDropdown>
          <a ngbNavLink class="py-0 tab text-dark text-active-primary"  [class.active]="activatedtabfile== 'Reviews'">
            <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1" ngbDropdownToggle><i class='bx bx-list-check me-1 icons-font'></i></span>
            <span class="d-sm-block1 newfontscolor texttabswhite py-1" ngbDropdownToggle><span class="d-flex align-items-center newfontscolornew"><i class='bx bx-list-check me-1 icons-font1'></i>Reviews</span></span>
          </a>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a class="dropdown-item text-dark" routerLink="/SiteAudit/Reviews/summary"
            (click)="menuItemClick('/SiteAudit/Reviews/summary')" [class.active]="activatedtabfiledropdowns== 'summary'">Summary
            </a>
            <a class="dropdown-item text-dark" routerLink="/SiteAudit/Reviews/financial-review"
            (click)="menuItemClick('/SiteAudit/Reviews/financial-review')" [class.active]="activatedtabfiledropdowns== 'financial-review'">Financial Review
            </a>
            <a class="dropdown-item text-dark" routerLink="/SiteAudit/Reviews/transaction"
            (click)="menuItemClick('/SiteAudit/Reviews/transaction')" [class.active]="activatedtabfiledropdowns== 'transaction'">Transaction Review
            </a>
            <a class="dropdown-item text-dark" routerLink="/SiteAudit/Reviews/lot-audit"
            (click)="menuItemClick('/SiteAudit/Reviews/lot-audit')"  [class.active]="activatedtabfiledropdowns== 'lot-audit'">Lot Audit
            </a>
            <a class="dropdown-item text-dark" routerLink="/SiteAudit/Reviews/pdf-audits"
            (click)="menuItemClick('/SiteAudit/Reviews/pdf-audits')"  [class.active]="activatedtabfiledropdowns== 'pdf-audits'">Audit
            </a>
          </div>
        </li>
      
        <li>
        </li>
     
      </ul> -->

      <ul ngbNav #customNav="ngbNav" [activeId]="1">
        <li class="py-2">
          <img [src]="profilePicture" style="height: 30px; width: 30px;"
            class="rounded-circle border-2 border ms-2 me-2 border-logo bg-white">
        </li>
        <!-- <li [ngbNavItem]="0" class="flex-grow-0 nav-item py-2">
          <a ngbNavLink class="py-0  tab nav-link " routerLink="/Dobby/Dashboard"
            (click)="menuItemClick('/Dobby/Dashboard')" [class.active]="activatedtabfile== 'Dashboard'">
            <span class=" d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1"><i class='bx bx-bar-chart-square me-1 icons-font'></i></span>
            <span class=" d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1"><span class="d-flex align-items-center"><i class='bx bx-bar-chart-square me-1 font-size-14'></i>Dashboard</span></span>
           
          </a>
        </li> -->
        <div *ngFor="let menus of menuItems" >
        <li [ngbNavItem]="0" class="flex-grow-0 flex-fill nav-item py-2"  *ngIf="menus.Menu !=='Census'">
          <ng-container *ngIf="menus.Menu !== 'Settings' && menus.Menu !== 'Reports'">
          <a ngbNavLink class="py-0 tab nav-link" [routerLink]="menus.Menu !== 'Reviews' ? menus.PageURL : null"
            (click)="menus.Menu !== 'Reviews' ? menuItemClick( menus.PageURL) : null"
            [class.active]="activatedtabfile == menus.MenuCode">
            <ng-container *ngIf="menus.Menu === 'Dashboard'">
              <span class="d-sm-none1 newfontscolor newfontscolornew texttabswhite py-1">
                <i class='bx {{menus.MenuIcon}} me-1 icons-font'></i>
              </span>
              <span class="d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1">
                <span class="d-flex align-items-center">
                  <i class='bx {{menus.MenuIcon}} me-1 font-size-14'></i>{{menus.Menu}}
                </span>
              </span>
            </ng-container>
            <ng-container *ngIf="menus.Menu !== 'Dashboard'">
              <span ngbDropdownToggle class="d-sm-none1 newfontscolor newfontscolornew texttabswhite cursor-pointer py-1">
                <i class='bx {{menus.MenuIcon}} me-1 icons-font'></i>
              </span>
              <span ngbDropdownToggle class="d-sm-block1 newfontscolor newfontscolornew texttabswhite py-1">
                <span class="d-flex align-items-center cursor-pointer">
                  <img src='{{menus.MenuIcon}}' width="20px" height="20px" class="text-white">{{menus.Menu}}
                </span>
              </span>
            </ng-container>
          </a>
          <div *ngIf="menus.LeftSubMenus && menus.LeftSubMenus.length > 0" class="dropdown-menu dropdown-menu-end"
            ngbDropdownMenu>
            <span *ngFor="let submenus of menus.LeftSubMenus">
              <a class="dropdown-item text-dark cursor-pointer" routerLink="{{submenus.PageURL}}"
                (click)="menuItemClick( submenus.PageURL)"
                [class.active]="activatedtabfiledropdowns== submenus.MenuCode">{{submenus.Menu}}
              </a>
            </span>
          </div>
          </ng-container>
        </li>
        </div>
      </ul>
      <div class="d-flex py-2">
        <div class="dropdown" ngbDropdown>
          <div ngbDropdownToggle><i class="bx bxs-cog icons-font1 mt-2 text-white"></i></div>
          <div class="dropdown-menu dropdown-menu-end px-2" ngbDropdownMenu>
            <span *ngFor="let submenusettings of settingsubmenus">
              <a class="dropdown-item text-dark newfontscolor" routerLink="{{submenusettings.PageURL}}"
                (click)="menuItemClick( submenusettings.PageURL)"
                [class.active]="submenusettings.Menu === 'Sites' ? activatedtabfile === submenusettings.MenuCode : activatedtabfiledropdowns === submenusettings.MenuCode"><span
                  class="d-flex align-items-center"><i
                    class='bx {{submenusettings.MenuIcon}} me-2 icons-font1'></i>{{submenusettings.Menu}}</span>
              </a>
            </span>
          </div>
        </div>
        <!-- <div class="dropdown" ngbDropdown>
          <div ngbDropdownToggle><i class="bx bxs-cog icons-font1 mt-2 text-white"></i></div>
          <div class="dropdown-menu dropdown-menu-end px-2" ngbDropdownMenu>
            <a class="dropdown-item text-dark newfontscolor" routerLink="/SiteAudit/Settings/TenantSettings"
            (click)="menuItemClick('/SiteAudit/Settings/TenantSettings')" [class.active]="activatedtabfiledropdowns== 'TenantSettings'"><span class="d-flex align-items-center"><i
                  class='bx bx-cog me-2 icons-font1'></i>Tenant Settings</span>
            </a>
            <a class="dropdown-item text-dark newfontscolor" routerLink="/SiteAudit/Sites"
            (click)="menuItemClick('/SiteAudit/Sites')" [class.active]="activatedtabfile== 'Sites'"><span class="d-flex align-items-center"><i
                  class='bx bx-buildings me-2 icons-font1'></i>Sites</span>
            </a>
            <a class="dropdown-item text-dark newfontscolor" routerLink="/SiteAudit/Settings/GroupSite"
            (click)="menuItemClick('/SiteAudit/Settings/GroupSite')" [class.active]="activatedtabfiledropdowns== 'GroupSite'"><span class="d-flex align-items-center"><i
                  class='bx bx-sitemap bx-rotate-270 me-2 icons-font1'></i>Site Groups</span>
            </a>
            <a class="dropdown-item text-dark newfontscolor" routerLink="/SiteAudit/Settings/ContactDetails"
            (click)="menuItemClick('/SiteAudit/Settings/ContactDetails')" [class.active]="activatedtabfiledropdowns== 'ContactDetails'"><span class="d-flex align-items-center"><i
                  class='bx bx-detail me-2 icons-font1'></i>Contact Details</span>
            </a>
            <a class="dropdown-item text-dark newfontscolor" routerLink="/SiteAudit/Settings/Multiple-Choices"
            (click)="menuItemClick('/SiteAudit/Settings/Multiple-Choices')" [class.active]="activatedtabfiledropdowns== 'Multiple-Choices'"><span class="d-flex align-items-center"><i
                  class='bx bx-select-multiple me-2 icons-font1'></i>Multiple Choices</span>
            </a>
            <a class="dropdown-item text-dark newfontscolor" routerLink="/SiteAudit/Settings/User-Permissions"
            (click)="menuItemClick('/SiteAudit/Settings/User-Permissions')" [class.active]="activatedtabfiledropdowns== 'User-Permissions'"><span class="d-flex align-items-center"><i
                  class='bx bx-user-check me-2 icons-font1'></i>User Permissions</span>
            </a>
            <a class="dropdown-item text-dark newfontscolor" routerLink="/SiteAudit/Settings/User-Administration"
            (click)="menuItemClick('/SiteAudit/Settings/User-Administration')" [class.active]="activatedtabfiledropdowns== 'User-Administration'"><span
                class="d-flex align-items-center"><i class='bx bx-user-pin me-2 icons-font1'></i>User
                Administration</span>
            </a>
          </div>
        </div> -->
        <div class="dropdown" ngbDropdown>
          <button type="button" class="btn header-item p-0" ngbDropdownToggle id="page-header-user-dropdown">
            <div class="d-flex">
              <img class="rounded-1 header-profile-user2 ms-3 me-2" src="{{profilePicture}}" alt="Image"
                style="height: 30px; width: 30px;">
            </div>
          </button>
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a class="dropdown-item text-dark pt-0 newfontscolor"> <b>{{userFirstName}}</b>
            </a>
            <div class="dropdown-divider pt-0"></div>
            <a class="dropdown-item text-dark d-flex align-items-center newfontscolor cursor-pointer"
              routerLink="/Profile/General"><i class="bx bx-user-circle  align-middle me-1"></i>Profile
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger cursor-pointer newfontscolor" (click)="logout()"><i
                class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              LogOut</a>
          </div>
        </div>
        <!-- <ul ngbNav #customNav="ngbNav" [activeId]="1">
  <li [ngbNavItem]="8" class="dropdown" ngbDropdown>
  <a ngbNavLink class="py-0 tab text-dark text-active-primary" [class.active]="activatedtabfile== 'Settings'">
  <span class="d-block d-sm-none mb-2 mt-2"><i class="bx bxs-group"></i></span>
  <span class="d-none d-sm-block newfontscolor text-dark text-active-white py-1" ngbDropdownToggle>Settings</span>
  </a>
  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
  <a class="dropdown-item text-dark" routerLink="/SiteAudit/Settings/tenantsettings">Tenant Settings
  </a>
  <a class="dropdown-item text-dark" routerLink="/SiteAudit/Sites">Sites
  </a>
  <a class="dropdown-item text-dark" routerLink="/SiteAudit/Settings/groupsite">Site Groups
  </a>
  <a class="dropdown-item text-dark" routerLink="/SiteAudit/Settings/contactdetails">Contact Details
  </a>
  <a class="dropdown-item text-dark" routerLink="/SiteAudit/Settings/multiple-choices">Multiple Choices
  </a>
  <a class="dropdown-item text-dark" routerLink="/SiteAudit/Settings/user-permissions">User Permissions
  </a>
  <a class="dropdown-item text-dark" routerLink="/SiteAudit/Settings/user-administration">User Administration
  </a>
  </div>
  </li>
  </ul> -->
      </div>
    </div>
  </div>
</div>