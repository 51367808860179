import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgOtpInputModule } from  'ng-otp-input';
import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule,NgbDropdown , NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from "ngx-toastr";

import { UserLoginService } from './core/services/user-login.service';
import { AuthGuard } from './core/services/authguard';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapComponent } from './profile/google-map/google-map.component';

import { LicenseManager } from 'ag-grid-enterprise';
import { AuthorizationInterceptor } from './Interceptors/authorization.interceptor';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = { url: environment.socketurl+'/pdf', options: {
  transports: ['websocket', 'polling'], // Use WebSocket and fallback to polling if needed
    upgrade: true,  // Ensure transport upgrade is allowed
    reconnection: true, // Enable reconnection attempts
    reconnectionAttempts: 5, // Max attempts for reconnection
    reconnectionDelay: 1000, // Delay between reconnection attempts (in milliseconds)
    timeout: 20000, // Set a timeout period for connection
    autoConnect: true, // Automatically connect on init
    path: '/socket.io/',  // Make sure the path matches your Nginx and Flask Socket.IO setup
    forceNew: true // Create a new connection for each instance
} };  // Adjust URL if needed


LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-051540}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{T/A_Nages__Anupindi}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_December_2024}____[v3]_[01]_MTczNTYwMzIwMDAwMA==a7a17a31ddbd5ce4e853a4e877ccf0b1');

if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {

}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,   
    GoogleMapComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyA3VS9lm0C-wNfi377zjGUxPjKgDso90vs'
    }),
    SocketIoModule.forRoot(config)
  ],
  bootstrap: [AppComponent],
  providers: [
    NgbDropdown, 
    UserLoginService,
    AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },

  ],
})
export class AppModule {
  constructor() {
    // Set AG Grid license key
    LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-051540}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{T/A_Nages__Anupindi}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_December_2024}____[v3]_[01]_MTczNTYwMzIwMDAwMA==a7a17a31ddbd5ce4e853a4e877ccf0b1');
 }
}
