// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map, switchMap, catchError, throwError, Observer } from 'rxjs';
import * as moment from 'moment-timezone';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SharedService {

    token_response: any;
    token: any;
    constructor(private http: HttpClient,private router: Router,) {
        this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
        this.token = this.token_response.access_token;
        console.log(this.token_response);
        console.log(this.token_response.userId);
        console.log(this.token_response.tenantId);
        window.addEventListener('storage', (event) => {
            if (event.key === 'sharedData') {
                this.usertimezone.next(event.newValue);
            }
        });
    }
    public name = new BehaviorSubject<string>('');
    public usertimezone = new BehaviorSubject<string>('');
    public usertimeformat = new BehaviorSubject<string>('');
    public userdateformat = new BehaviorSubject<string>('');
    public scheduledcount = new BehaviorSubject<string>('');
    public scheduledcountdue = new BehaviorSubject<string>('');
    public scheduledcountinprogress = new BehaviorSubject<string>('');
    public scheduledcountcompleted = new BehaviorSubject<string>('');
    public scheduledcountoverdue = new BehaviorSubject<string>('');
    public isLocationOn = new BehaviorSubject<boolean>(false);
    public userPicture = new BehaviorSubject<string>('');
    public userFirstName = new BehaviorSubject<string>('');
    public loginuserdeatils = new BehaviorSubject<string>('');
    public isShowSchedularTab = new BehaviorSubject<boolean>(true);
    public aftercompleteauditscount = new BehaviorSubject<string>('');
    public headerimage = new BehaviorSubject<string>('');
    public AuditUpcomingDaysRange = new BehaviorSubject<string>('');
    public AuditInProgressDaysRange = new BehaviorSubject<string>('');
    public AuditCompletedDaysRange = new BehaviorSubject<string>('');
    public AuditOverdueDaysRange = new BehaviorSubject<string>('');
    public AuditDueDaysRange = new BehaviorSubject<string>('');
    public afterchangeperimissionmenus = new BehaviorSubject<string>('');
    private countryDataUrl = '../assets/CountriesCodes.json';
    private CountryCallerCode = '';
    apiKey = 'AIzaSyA3VS9lm0C-wNfi377zjGUxPjKgDso90vs';
    getTimezone(lat: number, lng: number) {
        const apiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(
            Date.now() / 1000
        )}&key=${this.apiKey}`;

        return this.http.get(apiUrl);
    }

    setCountryCallerCode(code: string) {
        this.CountryCallerCode = code;
    }

    getCountryCallerCode() {
        this.CountryCallerCode = this.CountryCallerCode.replace('+', '');
        return this.CountryCallerCode;
    }

    getIPAddress(): Observable<{ ip: string }> {
        return this.http.get<{ ip: string }>('https://jsonip.com');
    }

    setname(name: string) {
        this.name.next(name);
    }

    getname(): BehaviorSubject<string> {
        return this.name;
    }

    setloginuserdeatils(deatils: string) {
        this.loginuserdeatils.next(deatils);
    }

    getloginuserdeatils(): BehaviorSubject<string> {
        return this.loginuserdeatils;
    }
    setAuditCompletedDaysRange(AuditCompletedDaysRange: string) {
        this.AuditCompletedDaysRange.next(AuditCompletedDaysRange);
    }

    getAuditCompletedDaysRange(): BehaviorSubject<string> {
        return this.AuditCompletedDaysRange;
    }


    setAuditInProgressDaysRange(AuditInProgressDaysRange: string) {
        this.AuditInProgressDaysRange.next(AuditInProgressDaysRange);
    }

    getAuditInProgressDaysRange(): BehaviorSubject<string> {
        return this.AuditInProgressDaysRange;
    }

    setAuditOverdueDaysRange(AuditOverdueDaysRange: string) {
        this.AuditOverdueDaysRange.next(AuditOverdueDaysRange);
        console.log(AuditOverdueDaysRange);
    }

    getAuditOverdueDaysRange(): BehaviorSubject<string> {
        console.log(this.AuditOverdueDaysRange)
        return this.AuditOverdueDaysRange;
    }

    setAuditDueDaysRange(AuditDueDaysRange: string) {
        this.AuditDueDaysRange.next(AuditDueDaysRange);
        console.log(AuditDueDaysRange);
    }

    getAuditDueDaysRange(): BehaviorSubject<string> {
        return this.AuditDueDaysRange;
    }

    setAuditUpcomingDaysRange(AuditUpcomingDaysRange: string) {
        console.log(AuditUpcomingDaysRange);
        this.AuditUpcomingDaysRange.next(AuditUpcomingDaysRange);
    }

    getAuditUpcomingDaysRange(): BehaviorSubject<string> {
        return this.AuditUpcomingDaysRange;
    }

    setuserdateformat(userdateformat: any) {
        this.userdateformat.next(userdateformat);
    }

    getuserdateformat(): BehaviorSubject<any> {
        return this.userdateformat;
    }


    setuserProfilePicture(userPicture: any) {
        this.userPicture.next(userPicture);
    }

    getuserProfilePicture(): BehaviorSubject<any> {
        return this.userPicture;
    }


    setuserFirstName(userFirstName: any) {
        this.userFirstName.next(userFirstName);
    }

    getuserFirstName(): BehaviorSubject<any> {
        return this.userFirstName;
    }

    setusertimeformat(usertimeformat: string) {
        this.usertimeformat.next(usertimeformat);
    }

    getusertimeformat(): BehaviorSubject<string> {
        return this.usertimeformat;
    }

    setusertimezone(usertimezone: string) {
        this.usertimezone.next(usertimezone);
    }

    getusertimezone(): BehaviorSubject<string> {
        return this.usertimezone;
    }

    setscheduledcount(scheduledcount: any) {
        this.scheduledcount.next(scheduledcount);
    }

    getscheduledcount(): BehaviorSubject<string> {
        return this.scheduledcount;
    }

    setscheduledcountdue(scheduledcountdue: any) {
        this.scheduledcountdue.next(scheduledcountdue);
    }

    getscheduledcountdue(): BehaviorSubject<string> {
        return this.scheduledcountdue;
    }

    setscheduledcountinprogress(scheduledcountinprogress: any) {
        this.scheduledcountinprogress.next(scheduledcountinprogress);
    }

    getscheduledcountinprogress(): BehaviorSubject<string> {
        return this.scheduledcountinprogress;
    }

    setscheduledcountcompleted(scheduledcountcompleted: any) {
        this.scheduledcountcompleted.next(scheduledcountcompleted);
    }

    getscheduledcountcompleted(): BehaviorSubject<string> {
        return this.scheduledcountcompleted;
    }

    setscheduledcountoverdue(scheduledcountoverdue: any) {
        this.scheduledcountoverdue.next(scheduledcountoverdue);
    }

    getIsShowSchedularTab(): BehaviorSubject<boolean> {
        return this.isShowSchedularTab;
    }

    setIsShowSchedularTab(isShowSchedularTab: boolean) {
        this.isShowSchedularTab.next(isShowSchedularTab);
    }

    getscheduledcountoverdue(): BehaviorSubject<string> {
        return this.scheduledcountoverdue;
    }

    setisLocationOn(isLocationOn: boolean) {
        this.isLocationOn.next(isLocationOn);
    }

    getaftercompleteauditscount(): BehaviorSubject<string> {
        return this.aftercompleteauditscount;
    }

    setaftercompleteauditscount(aftercompleteauditscount: any) {
        this.aftercompleteauditscount.next(aftercompleteauditscount);
    }

    getafterheaderimage(): BehaviorSubject<string> {
        return this.headerimage;
    }

    setafterheaderimage(headerimage: any) {
        this.headerimage.next(headerimage);
    }

    getafterchangeperimissionmenus(): BehaviorSubject<string> {
        return this.afterchangeperimissionmenus;
    }

    setafterchangeperimissionmenus(afterchangeperimissionmenus: any) {
        this.afterchangeperimissionmenus.next(afterchangeperimissionmenus);
    }

    GetFormatedTime(date: any) {
        let token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
        if (token_response) {
            return moment(date).format("HH:mm")
        }
        else {
            return moment(date).format("HH:mm A")
        }
    }

    GetFormatedDateTime(date: any) {
        let token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
        if (token_response) {
            return moment(date).format("MM/DD/yyyy HH:mm")
        }
        else {
            return moment(date).format("MM/DD/yyyy HH:mm A")
        }
    }

    getisLocationOn(): BehaviorSubject<boolean> {
        return this.isLocationOn;
    }

    GetUserLocalDateTime() {
        return moment().format("MM/DD/yyyy HH:mm:ss.SSS");
    }

    GetOneHourHeadofUserLocalDateTime() {
        const currentDateTime = moment();
        const newDateTime = currentDateTime.add(5, 'minutes');
        return newDateTime.format("MM/DD/yyyy HH:mm:ss.SSS");
    }

    GetUserTimezoneAbbreviation() {
        // let localTimeZone = moment.tz.guess();
        // return moment().tz(localTimeZone).format('z');

        let localTimeZone = moment.tz.guess();
        let timeZoneAbbr = moment().tz(localTimeZone).format('z');

        // Manually map timezone abbreviations
        let abbreviationMap = {
            'Asia/Dubai': 'GST', // Gulf Standard Time
            'Asia/Muscat': 'GST' // Gulf Standard Time
            // Add more mappings as needed
        };

        return abbreviationMap[localTimeZone] || timeZoneAbbr;
    }

    GetUserTimezoneLocation() {
        return moment.tz.guess();
    }

    GetLocation(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;
                        this.getUserCountryCode(latitude, longitude)
                            .then((callercode) => resolve(callercode))
                            .catch((error) => reject(error));
                    },
                    (error) => {
                        console.error('Geolocation error:', error);
                        reject('Geolocation error');
                    }
                );
            } else {
                console.error('Geolocation is not supported by your browser.');
                reject('Geolocation not supported');
            }
        });
    }

    getUserCountryCode(latitude: number, longitude: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const apiKey = 'AIzaSyA3VS9lm0C-wNfi377zjGUxPjKgDso90vs';
            const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

            this.http.get(geocodingUrl).subscribe((response: any) => {
                const addressComponents = response.results[0].address_components;
                for (const component of addressComponents) {
                    if (component.types.includes('country')) {
                        let userCountryCode = component.short_name;
                        this.http.get(this.countryDataUrl).subscribe((data: any) => {
                            const userCountry = data.find((country) => country.code === userCountryCode);
                            if (userCountry) {
                                const callercode = userCountry.dial_code;
                                this.isLocationOn.next((callercode != '' && callercode != undefined && callercode != null) ? true : false);
                                this.setCountryCallerCode(callercode);
                                resolve(callercode);
                            } else {
                                reject('Country code not found');
                            }
                        });
                        break;
                    }
                }
            }, (error) => {
                console.error('Geocoding API error:', error);
                reject('Geocoding API error');
            });
        });
    }

    GetUserPreferencesDetails(userProfileId: any, tenantId: any, moduleName: any, screenName: any) {
        return this.http.get(environment.GetUserPreferencesDetails + '/' + userProfileId + '/' + tenantId + '/' + moduleName + '/' + screenName).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            console.log(error);
            return throwError(error);
        }));

    }
    SaveUserPreferenceDetails(userPreferenceModel: any) {
        console.log("sample")
        return this.http.post(environment.SaveUserPreferenceDetails, userPreferenceModel, {
            responseType: 'text'
        }).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            console.log(error);
            return throwError(error);
        }));
    }

    GetDSBAuditsById() {
        this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
        return this.http.get(environment.appDSBAuditsById + '/' + this.token_response.userId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            console.log(error);
            return throwError(error);
        }));
    }



    GetDSBAuditsInProgressByUserProfileId() {
        this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
        return this.http.get(environment.GetDSBAuditsInProgressByUserProfileId + '/' + this.token_response.userId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            console.log(error);
            return throwError(error);
        }));
    }

    // getCurrentLocation(): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.http.get('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBJCZrrfMouhD7RjIjEgofZUpiTLu9qeDI').subscribe((response: any) => {
    //             resolve(response.location);
    //         },
    //             (error) => {
    //                 reject(error);
    //             });
    //     });
    // }

    getCurrentLocation(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position: GeolocationPosition) => {
                observer.next(position.coords);
                observer.complete();
              },
              (error: GeolocationPositionError) => {
                observer.error(error);
              }
            );
          } else {
            observer.error('Geolocation is not supported by this browser.');
          }
        });
      }
      private timeout: any;
       private readonly inactivityDuration: number = 8 * 60 * 60 * 1000;
    // private readonly inactivityDuration: number = 10 * 1000; 
      startTimer() {
        this.timeout = setTimeout(() => this.logout(), this.inactivityDuration);
      }
    
      // Method to reset the inactivity timer
      resetTimer() {
        clearTimeout(this.timeout);
        this.startTimer();
      }

      logout() {
        localStorage.removeItem('siteaudit_token_response');
        localStorage.removeItem('IsUser_loggedin');
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('Dateformat');
        localStorage.removeItem('Dateformat1');
        localStorage.removeItem('Timeformat');
        localStorage.removeItem('Timeformat1');
        localStorage.removeItem('TimeZone');
        localStorage.removeItem('TimeZone1');
        localStorage.removeItem('UserPicture');
        localStorage.removeItem('UserFirstName');
        localStorage.removeItem('userroleId');
        localStorage.clear();
        this.router.navigate(['/login']);
        location.reload()
      }
}
