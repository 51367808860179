import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserLoginService {
  token_response: any;
  token: any;
  private targetScreen: string;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
    this.token = this.token_response.access_token;
  }

  setTargetScreen(url: string): void {
    this.targetScreen = url;
  }

  getTargetScreen(): string {
    return this.targetScreen;
  }

  GetUserDetailsByPhoneNumber(userModel: any) {
    return this.http.post(environment.GetUserDetailsByPhoneNumber, userModel).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  userAuthentication(formData:any) {
    return this.http.post(environment.tokenUrl, formData).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveNewUser(newUser: any) {
    return this.http.post(environment.SaveNewUserDetails, newUser, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetMenusByUserProfileIdAndUserRoleId() {
    this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
    let roleid =  localStorage.getItem("userroleId");
    console.log(this.token_response);
    return this.http.get(environment.GetMenusByUserProfileIdAndUserRoleId + '/' + this.token_response.userId + '/' + this.token_response.tenantId +'/'+roleid)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetUserRolesByTenantId() {
    this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
    console.log(this.token_response);
    return this.http.get(environment.GetUserRolesByTenantId + '/' + this.token_response.tenantId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  ValidateReCaptchaResponse(model:any){
  
    console.log(environment.ValidateReCaptchaResponse, model);
    return this.http.post(environment.ValidateReCaptchaResponse, model, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}
