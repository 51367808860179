import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';

import { EventService } from '../../core/services/event.service';
// import { AuthenticationService } from '../../core/services/auth.service';
// import { AuthfakeauthenticationService } from '../../core/services/authfake.service';

import { DOCUMENT } from '@angular/common';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { environment } from '../../../environments/environment';
import { SharedService } from 'src/app/core/services/SharedService.service';
import { UserLoginService } from 'src/app/core/services/user-login.service';
import { TostrAlertService } from 'src/app/core/services/tostr-alert.service';
import { TenantsettingsService } from 'src/app/core/services/tenantsettings.service';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss']
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit {

  activatedtabfile: string;
  showtoggle = true;
  token_response: any;
  token: any;
  userFirstName: any;
  userPicture: string;
  activatedtabfiledropdowns: string;
  menuItems: any[];
  settingsubmenus: any = [];
  profilePicture: any;
  userProfileDetailList: any;
  userProfileID: any;
  fileURLList: any;

  constructor(private router: Router, private sharedService: SharedService, private userLoginService: UserLoginService, public tenantsettingsService: TenantsettingsService, public toastralert: TostrAlertService, private profileService: ProfileService,) {
    var str = this.router.url;
    console.log(str)
    this.activatedtabfile = str.split('/')[2];
    this.activatedtabfiledropdowns = str.split('/')[3];
    console.log(this.activatedtabfile, this.activatedtabfiledropdowns)
    this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
    this.token = this.token_response.access_token;
    this.userFirstName = this.token_response.FirstName
    console.log(this.userFirstName);
  }

  ngOnInit(): void {
    this.token_response = JSON.parse(localStorage.getItem("userDetails") || '{}');
    this.userProfileID = this.token_response.UserProfileId;
    console.log(this.userProfileID, "UserProfile");
    // this.sharedService.getafterheaderimage().subscribe((data) => {
    //   this.GetTenantData();
    // });
    //this.GetTenantData();
    this.sharedService.getafterchangeperimissionmenus().subscribe((data) => {
      this.GetMenusByUserProfileIdAndUserRoleId();
    });
    this.GetMenusByUserProfileIdAndUserRoleId();
    this.GetUserProfileDetailsByUserProfileId();
    this.sharedService.getuserProfilePicture().subscribe((data) => {
      //this.GetTenantTimeZone();
      console.log(data);
      // if (data) {
      //   localStorage.setItem("UserPicture", data);
      //   this.userPicture = localStorage.getItem('UserPicture');
      // } else {
      //   this.userPicture = localStorage.getItem('UserPicture');
      // }
      console.log(this.userPicture);
    });
    console.log(this.userPicture);

    this.sharedService.getuserFirstName().subscribe((data) => {
      if (data) {
        localStorage.setItem("UserFirstName", data);
        this.userFirstName = localStorage.getItem('UserFirstName');
      } else {
        this.userFirstName = localStorage.getItem('UserFirstName');
      }
      console.log(this.userFirstName);
    });

  }
  GetTenantData() {
    this.tenantsettingsService.GetTenantSettingsByTenantId().subscribe((result) => {
      console.log(result, 'GetTenantData');

      if (result.SiteLogo == '../../../assets/images/th.jpg') {
        this.profilePicture = "../../../assets/images/siteauditlogo-sm.png"
      }
      else if (result.SiteLogo != null && result.SiteLogo != undefined && result.SiteLogo != "") {
        this.profilePicture = result.SiteLogo;
      }
      else {
        this.profilePicture = "../../../assets/images/siteauditlogo-sm.png"
      }
      console.log(this.profilePicture);
    });
  }

  GetUserProfileDetailsByUserProfileId() {
    this.profileService.GetUserProfileDetailsByUserProfileId(this.userProfileID).subscribe((result) => {
      this.userProfileDetailList = result;
      if (this.userProfileDetailList.UserPicture == "" || this.userProfileDetailList.UserPicture == null || this.userProfileDetailList.UserPicture == undefined) {
        this.profilePicture = 'assets/images/avatar.png';
      } else {
        if(this.userProfileDetailList.UserPicture == 'assets/images/avatar.png'){
          this.profilePicture = this.userProfileDetailList.UserPicture;
        }
        else{
          this.GetFileURL(this.userProfileDetailList.UserPicture);

        }
      }
    },
      (err) => {
        console.log("Error Occurred While Getting Dynamic Form Components Data.");
      }
    )
    console.log(this.userProfileDetailList);
  }

  GetFileURL(fileName){
    this.profileService.GetFileURL(fileName).subscribe((result) => {
      this.fileURLList = result;
      if(this.fileURLList!="" && this.fileURLList!=null && this.fileURLList!=undefined){
          this.profilePicture = this.fileURLList;
      }
    });
  }

  // GetTenantTimeZone() {
  //   this.profileService.GetTenantUserTimeZone().subscribe((result) => {
  //     if (result.UserPicture == "" || result.UserPicture == null || result.UserPicture == undefined) {
  //       this.userPicture = 'assets/images/avatar.png';
  //     } else {
  //       this.userPicture = result.UserPicture;
  //     }

  //   },
  //     (err) => {
  //       console.log("Error Occurred While Getting Dynamic Form Components Data.");
  //     }
  //   )
  // }
  menuItemClick(menuUrl: any): void {
    console.log('Menu clicked:', menuUrl);
    this.activatedtabfile = menuUrl.split('/')[2];
    this.activatedtabfiledropdowns = menuUrl.split('/')[3];
    console.log(this.activatedtabfile, this.activatedtabfiledropdowns)

  }

  getallauditscount() {
    console.log("count");
    this.sharedService.setaftercompleteauditscount('');
  }

  // logout() {
  //   localStorage.removeItem('siteaudit_token_response');
  //   localStorage.removeItem('IsUser_loggedin');
  //   localStorage.removeItem('isAuthenticated');
  //   localStorage.removeItem('Dateformat');
  //   localStorage.removeItem('Dateformat1');    
  //   localStorage.removeItem('Timeformat');
  //   localStorage.removeItem('Timeformat1');
  //   localStorage.removeItem('TimeZone');
  //   localStorage.removeItem('TimeZone1');    
  //   localStorage.removeItem('UserPicture');
  //   localStorage.removeItem('UserFirstName');
  //    this.router.navigate(['/login']);
  // }

  logout() {
    localStorage.removeItem('siteaudit_token_response');
    localStorage.removeItem('IsUser_loggedin');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('Dateformat');
    localStorage.removeItem('Dateformat1');
    localStorage.removeItem('Timeformat');
    localStorage.removeItem('Timeformat1');
    localStorage.removeItem('TimeZone');
    localStorage.removeItem('TimeZone1');
    localStorage.removeItem('UserPicture');
    localStorage.removeItem('UserFirstName');
    localStorage.removeItem('userroleId');
    localStorage.clear();
    this.router.navigate(['/login']);
    location.reload()
  }

  toggleDropdown() {
    this.showtoggle = !this.showtoggle;
  }

  redirectTODashboard() {
    this.router.navigate(['/Dobby/dashboard']);
  }
  // redirectTOPatientBilling() {
  //   this.router.navigate(['/Dobby/PatientBilling']);
  // }
  redirectTOFacesheet() {
    this.router.navigate(['/Dobby/Facesheet']);
  }
  redirectTOMartar() {
    this.router.navigate(['/Dobby/Martar']);
  }
  redirectTOAudits() {
    this.router.navigate(['/SiteAudit/Audits/Audits-Completed']);
  }

  redirectTOIssues() {
    this.router.navigate(['/SiteAudit/Issues']);
  }

  redirectTOActions() {
    this.router.navigate(['/SiteAudit/Actions']);
  }

  redirectTOTemplates() {
    this.router.navigate(['/SiteAudit/Templates']);
  }

  redirectTOSites() {
    this.router.navigate(['/SiteAudit/sites']);
  }

  redirectTOPmsData() {
    this.router.navigate(['/SiteAudit/PmsData']);
  }

  GetMenusByUserProfileIdAndUserRoleId() {
    this.menuItems = [];
    this.userLoginService.GetMenusByUserProfileIdAndUserRoleId().subscribe((results) => {

      if (results != null && results != 'undefined') {
        console.log(results)
        this.menuItems = results;
        this.menuItems.forEach(element => {
          if (element.Menu == 'Settings') {
            this.settingsubmenus = element.LeftSubMenus;
            console.log(this.settingsubmenus)
          }
        })
        // this.settingsubmenus=[
        //   {
        //     PageURL:'/Dobby/Settings/Keywords',
        //     Menu:'Keywords',
        //     MenuCode:'Keywords',
        //     MenuIcon:'bx-detail'
        //   }
        // ]
        console.log(this.menuItems);
      }
    },
      (err) => {
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom("Unable to get menus.Please try again after some time.");
        }
      }
    );

  }
  // element;
  // cookieValue;
  // flagvalue;
  // countryName;
  // valueset;

  // menuItems = [];

  // listLang = [
  //   { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
  //   { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
  //   { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
  //   { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
  //   { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  // ];

  // // tslint:disable-next-line: max-line-length
  // constructor(@Inject(DOCUMENT) private document: any, private router: Router, private eventService: EventService, 
  //   public languageService: LanguageService,
  //   // tslint:disable-next-line: variable-name
  //   public _cookiesService: CookieService) {
  //   router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       this.activateMenu();
  //     }
  //   });
  // }

  // ngOnInit(): void {
  //   this.element = document.documentElement;

  //   this.initialize();

  //   this.cookieValue = this._cookiesService.get('lang');
  //   const val = this.listLang.filter(x => x.lang === this.cookieValue);
  //   this.countryName = val.map(element => element.text);
  //   if (val.length === 0) {
  //     if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
  //   } else {
  //     this.flagvalue = val.map(element => element.flag);
  //   }
  // }

  // setLanguage(text: string, lang: string, flag: string) {
  //   this.countryName = text;
  //   this.flagvalue = flag;
  //   this.cookieValue = lang;
  //   this.languageService.setLanguage(lang);
  // }

  // /**
  //  * Logout the user
  //  */
  // logout() {
  //   if (environment.defaultauth === 'firebase') {
  //     // this.authService.logout();
  //   } else {
  //     // this.authFackservice.logout();
  //   }
  //   this.router.navigate(['/account/login']);
  // }

  // /**
  //  * On menu click
  //  */
  // onMenuClick(event) {
  //   const nextEl = event.target.nextElementSibling;
  //   if (nextEl) {
  //     const parentEl = event.target.parentNode;
  //     if (parentEl) {
  //       parentEl.classList.remove("show");
  //     }
  //     nextEl.classList.toggle("show");
  //   }
  //   return false;
  // }

  // ngAfterViewInit() {
  //   this.activateMenu();
  // }

  // /**
  //  * remove active and mm-active class
  //  */
  // _removeAllClass(className) {
  //   const els = document.getElementsByClassName(className);
  //   while (els[0]) {
  //     els[0].classList.remove(className);
  //   }
  // }

  // /**
  //  * Togglemenu bar
  //  */
  // toggleMenubar() {
  //   const element = document.getElementById('topnav-menu-content');
  //   element.classList.toggle('show');
  // }

  // /**
  //  * Activates the menu
  //  */
  // private activateMenu() {

  //   const resetParent = (el: any) => {
  //     const parent = el.parentElement;
  //     if (parent) {
  //       parent.classList.remove('active');
  //       const parent2 = parent.parentElement;
  //       this._removeAllClass('mm-active');
  //       this._removeAllClass('mm-show');
  //       if (parent2) {
  //         parent2.classList.remove('active');
  //         const parent3 = parent2.parentElement;
  //         if (parent3) {
  //           parent3.classList.remove('active');
  //           const parent4 = parent3.parentElement;
  //           if (parent4) {
  //             parent4.classList.remove('active');
  //             const parent5 = parent4.parentElement;
  //             if (parent5) {
  //               parent5.classList.remove('active');
  //               const menuelement = document.getElementById("topnav-menu-content")
  //               if (menuelement !== null) {
  //                 if (menuelement.classList.contains('show'))
  //                   document
  //                     .getElementById("topnav-menu-content")
  //                     .classList.remove("show");
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   };

  //   // activate menu item based on location
  //   const links = document.getElementsByClassName('side-nav-link-ref');
  //   let matchingMenuItem = null;
  //   // tslint:disable-next-line: prefer-for-of
  //   for (let i = 0; i < links.length; i++) {
  //     // reset menu
  //     resetParent(links[i]);
  //   }
  //   // tslint:disable-next-line: prefer-for-of
  //   for (let i = 0; i < links.length; i++) {
  //     // tslint:disable-next-line: no-string-literal
  //     if (location.pathname === links[i]['pathname']) {
  //       matchingMenuItem = links[i];
  //       break;
  //     }
  //   }

  //   if (matchingMenuItem) {
  //     const parent = matchingMenuItem.parentElement;
  //     /**
  //      * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
  //      * We should come up with non hard coded approach
  //      */
  //     if (parent) {
  //       parent.classList.add('active');
  //       const parent2 = parent.parentElement;
  //       if (parent2) {
  //         parent2.classList.add('active');
  //         const parent3 = parent2.parentElement;
  //         if (parent3) {
  //           parent3.classList.add('active');
  //           const parent4 = parent3.parentElement;
  //           if (parent4) {
  //             parent4.classList.add('active');
  //             const parent5 = parent4.parentElement;
  //             if (parent5) {
  //               parent5.classList.add('active');
  //               const parent6 = parent5.parentElement;
  //               if (parent6) {
  //                 parent6.classList.add('active');
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // /**
  //  * on settings button clicked from topbar
  //  */
  // onSettingsButtonClicked() {
  //   document.body.classList.toggle('right-bar-enabled');
  // }

  // /**
  //  * Fullscreen method
  //  */
  // fullscreen() {
  //   document.body.classList.toggle('fullscreen-enable');
  //   if (
  //     !document.fullscreenElement && !this.element.mozFullScreenElement &&
  //     !this.element.webkitFullscreenElement) {
  //     if (this.element.requestFullscreen) {
  //       this.element.requestFullscreen();
  //     } else if (this.element.mozRequestFullScreen) {
  //       /* Firefox */
  //       this.element.mozRequestFullScreen();
  //     } else if (this.element.webkitRequestFullscreen) {
  //       /* Chrome, Safari and Opera */
  //       this.element.webkitRequestFullscreen();
  //     } else if (this.element.msRequestFullscreen) {
  //       /* IE/Edge */
  //       this.element.msRequestFullscreen();
  //     }
  //   } else {
  //     if (this.document.exitFullscreen) {
  //       this.document.exitFullscreen();
  //     } else if (this.document.mozCancelFullScreen) {
  //       /* Firefox */
  //       this.document.mozCancelFullScreen();
  //     } else if (this.document.webkitExitFullscreen) {
  //       /* Chrome, Safari and Opera */
  //       this.document.webkitExitFullscreen();
  //     } else if (this.document.msExitFullscreen) {
  //       /* IE/Edge */
  //       this.document.msExitFullscreen();
  //     }
  //   }
  // }

  // /**
  //  * Initialize
  //  */
  // initialize(): void {
  //   this.menuItems = MENU;
  // }

  // /**
  //  * Returns true or false if given menu item has child or not
  //  * @param item menuItem
  //  */
  // hasItems(item: MenuItem) {
  //   return item.subItems !== undefined ? item.subItems.length > 0 : false;
  // }

}
